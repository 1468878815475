<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
<template>
  <div>
    <b-form @submit.prevent="formSubmitted">
      <b-row>
        <b-col md="6">
          <b-form-group label="Transaction Type" label-for="trxType">
            <b-form-select
              id="trxType"
              name="trxType"
              v-model="transaction.type"
              :options="types"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Transaction Date" label-for="trxDate">
            <b-form-datepicker
              id="trxDate"
              v-model="transaction.date"
              class="mb-1"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="From Warehouse" label-for="fromWarehouse">
            <b-form-select
              id="fromWarehouse"
              name="fromWarehouse"
              v-model="transaction.fromWarehouse"
              :options="warehouses"
              value-field="id"
              text-field="name"
            >
              <option value="">None</option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="To Warehouse" label-for="toWarehouse">
            <b-form-select
              id="toWarehouse"
              name="toWarehouse"
              v-model="transaction.toWarehouse"
              :options="warehouses"
              value-field="id"
              text-field="name"
            >
              <option value="">None</option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" class="mb-2">
          <label for="trxRef">Transaction Reference</label>
          <b-form-textarea
            id="trxRef"
            name="trxRef"
            placeholder="Adjustment for case A05"
            rows="3"
            v-model="transaction.refNo"
          />
        </b-col>
      </b-row>
    </b-form>
    <b-form
      ref="form"
      :style="{ height: trHeight }"
      class="repeater-form"
      @submit.prevent="addLine"
    >
      <b-row>
        <b-col cols="12">
          <hr />
        </b-col>
      </b-row>
      <b-row
        v-for="(item, index) in transaction.inventoryTransactionLine"
        :id="item.serial"
        :key="item.serial"
        ref="row"
      >
        <b-col md="3">
          <b-form-group label="Product / Raw Material" label-for="itemId">
            <b-form-select
              v-model="item.itemId"
              id="itemId"
              name="itemId"
              :options="items"
              value-field="id"
              text-field="name"
              @change="itemChanged(item)"
            />
          </b-form-group>
        </b-col>
        <!-- Quantity -->
        <b-col md="2">
          <b-form-group label="Unit of Measure" label-for="itemUom">
            <b-form-select
              v-model="item.uomId"
              id="itemUom"
              name="itemUom"
              :options="itemUoms"
              value-field="id"
              text-field="name"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group label="Qty" label-for="itemQty">
            <b-form-input
              v-model="item.qty"
              id="itemQty"
              name="itemQty"
              type="number"
              min="1"
              max="100"
              placeholder=""
            />
          </b-form-group>
        </b-col>
        <!-- Remove Button -->
        <b-col lg="3" md="3" class="mb-50">
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-danger"
            class="mt-0 mt-md-2"
            @click="removeLine(index)"
          >
            <feather-icon icon="XIcon" class="mr-25" />
            <span>Delete</span>
          </b-button>
        </b-col>
        <b-col cols="12">
          <hr />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="addLine"
            :disabled="
              loading ||
              productLoading ||
              materialLoading ||
              uomLoading ||
              warehouseLoading
            "
          >
            <feather-icon icon="PlusIcon" class="mr-25" />
            <span>Add Line</span>
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <b-row>
      <b-col cols="12" style="text-align: right">
        <b-button
          variant="outline-primary"
          class="btn btn-primary"
          @click="addTransaction()"
          ><b-spinner
            small
            v-if="
              loading ||
              productLoading ||
              materialLoading ||
              uomLoading ||
              warehouseLoading
            "
            class="mx-2"
          />Save and Post</b-button
        >
      </b-col>
    </b-row>
  </div>
</template>    
<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormSelect,
  BFormInput,
  BFormTextarea,
  BFormDatepicker,
  BButton,
  BDropdown,
  BDropdownItem,
  BSpinner,
} from "bootstrap-vue";

import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BFormTextarea,
    BFormDatepicker,
    BButton,
    BDropdown,
    BDropdownItem,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      items: [],
      types: [
        { value: 1, text: "PURCHASE" },
        { value: 2, text: "PRODUCTION" },
        { value: 3, text: "SALES" },
        { value: 4, text: "RETURN" },
        { value: 5, text: "DEFECT" },
        { value: 6, text: "LOST" },
        { value: 7, text: "STOLEN" },
        { value: 8, text: "TRANSFER" },
      ],
      transaction: {
        type: null,
        fromWarehouse: "",
        toWarehouse: "",
        date: new Date(),
        refNo: null,
        posted: true,
        postedDate: new Date(),
        inventoryTransactionLine: [],
      },
      nextSerial: 0,
    };
  },
  methods: {
    ...mapActions("inventoryTransactionModule", ["addTransactionAction"]),
    ...mapActions("productModule", ["getProductListAction"]),
    ...mapActions("rawmaterialModule", ["getRawMaterialListAction"]),
    ...mapActions("itemUomModule", ["getItemUOMListAction"]),
    ...mapActions("warehouseModule", ["getWarehouseListAction"]),    
    addLine() {
      let line = {
        serial: this.nextSerial,
        itemId: null,
        uomId: null,
        qty: 0,
      };
      this.transaction.inventoryTransactionLine.push(line);
      this.nextSerial += 1;

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },
    removeLine(index) {
      this.transaction.inventoryTransactionLine.splice(index, 1);
      this.transaction.inventoryTransactionLine.forEach((x, index) => {
        x.serial = index;
      });
      this.nextSerial -= 1;
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
    itemChanged(item) {
      let selectedItem = this.items.filter((obj) => {
        return obj.id === item.itemId;
      });
      if (selectedItem.length > 0) {
        item.uomId = selectedItem[0].defaultUomID;
      }
    },
    created() {
      window.addEventListener("resize", this.initTrHeight);
    },
    destroyed() {
      window.removeEventListener("resize", this.initTrHeight);
    },
    addTransaction() {
      let isValid = true;
      if(!this.transaction.type) {
        this.showMessage(
          "error",
          "Inventory Transaction",
          "You must choose transaction type!"
        );
        isValid = false;
      }
      else if (!this.transaction.fromWarehouse && !this.transaction.toWarehouse) {
        this.showMessage(
          "error",
          "Inventory Transaction",
          "You must choose from warehouse or to warehouse or both!"
        );
        isValid = false;
      } else if (this.transaction.inventoryTransactionLine.length === 0) {
        this.showMessage(
          "error",
          "Inventory Transaction",
          "Cannot save transaction without lines!"
        );
        isValid = false;
      } else {
        this.transaction.inventoryTransactionLine.forEach((x) => {
          if (!x.itemId || (x.qty ?? 0) === 0) {
            this.showMessage(
              "error",
              "Inventory Transaction",
              "Cannot save transaction line serial [" +
                x.serial +
                "] has invalid input!"
            );
            isValid = false;
          }
        });
      }
      if (isValid) {
        if(!this.transaction.fromWarehouse) {
          this.transaction.fromWarehouse = null;
        }
        if(!this.transaction.toWarehouse) {
          this.transaction.toWarehouse = null;
        }
        this.transaction.inventoryTransactionLine.forEach((x) => {
          x.serial = x.serial.toString();
        });
        this.addTransactionAction(this.transaction)
          .then(() => {
            this.success();
          })
          .catch((ex) => {
            this.error(ex.response.data);
          });
      }
    },
    showMessage(icon, title, msg, reRoute) {
      this.$swal({
        title: title,
        text: msg,
        icon: icon,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value && reRoute) {
          this.$router.push({ name: reRoute });
        }
      });
    },
    success() {
      this.$swal({
        title: "Inventory Transactions",
        text: "You have successfully added the transaction!",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$router.push({ name: "inventory-transaction-list" });
        }
      });
    },
    error(data) {
      let msg = null;
      if (data) msg = this.getErrorString(data);
      this.$swal({
        title: "Error!",
        //text: msg ?? ' Failed!',
        html: msg ?? " Failed!",
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    getErrorString(data) {
      let msg = null;
      if (data.errors) {
        let errors = data.errors;
        msg = "<div>";
        msg += "<p>Cannot post your request due to the following errors:</p>";
        for (var prop in errors) {
          if (
            Object.prototype.hasOwnProperty.call(errors, prop) &&
            prop !== "request"
          ) {
            msg += "<p>";
            msg += prop.replace("$.", "");
            msg += ": ";
            msg += errors[prop].toString().replace("$.", "");
            msg += "</p>";
          }
        }
        msg += "</div>";
      } else if (data.message) {
        msg = "<div>";
        msg += "<p>Cannot post your request due to the following errors:</p>";
        msg += data.message;
        msg += "</div>";
      }
      return msg;
    },
  },
  computed: {
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    ...mapGetters("inventoryTransactionModule", {
      loading: "loading",
    }),
    ...mapGetters("productModule", {
      ProductList: "product",
      productLoading: "loading",
    }),
    ...mapGetters("rawmaterialModule", {
      RawMaterialList: "rawmaterials",
      materialLoading: "loading",
    }),
    ...mapGetters("itemUomModule", {
      itemUoms: "itemuom",
      uomLoading: "loading",
    }),
    ...mapGetters("warehouseModule", {
      warehouses: "warehouse",
      warehouseLoading: "loading",
    }),
  },
  async mounted() {
    this.initTrHeight();
    await this.getWarehouseListAction();
    await this.getProductListAction().then(() => {
      this.ProductList.map((x) => {
        if (x.defaultUomId) {
          this.items.push({
            id: x.id,
            name: x.name,
            defaultUomID: x.defaultUomId,
          });
        }
      });
    });
    await this.getRawMaterialListAction().then(() => {
      this.RawMaterialList.map((x) => {
        if (x.defaultUomID) {
          this.items.push({
            id: x.id,
            name: x.name,
            defaultUomID: x.defaultUomID,
          });
        }
      });
    });
    await this.getItemUOMListAction();
    this.addLine();
  },
};
</script>